import React from "react"
import formatDate from "../../../utils/formatDate"
import { Link } from "gatsby"

const Others = ({ article }) => {
  let date = formatDate(article.createdAt)

  return (
    <Link to={`/articles/${article.slug}`} className="other-news">
      <div className="content">
        <h3 className="title">{article.title}</h3>
        <p className="date">{date}</p>
      </div>
    </Link>
  )
}

export default Others
