import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import formatDate from "../../../utils/formatDate";
import { useFeaturedImage } from "../../../hooks/useFeaturedImage";
import BackgroundImage from "gatsby-background-image";
import responsiveStringLength from "../../../utils/responsiveStringLength";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

const Featured = ({ latestArticle }) => {
  // Default image if post has no image
  const { childImageSharp } = useFeaturedImage();

  // Exctract article data
  let slug = latestArticle.slug;
  let title = latestArticle.title;
  let date = formatDate(latestArticle.createdAt);
  let body = documentToPlainTextString(latestArticle.body.json);
  let imageExists = latestArticle.featuredImage
    ? latestArticle.featuredImage.fluid
    : false;
  let image = imageExists || childImageSharp.fluid;
  const [excerpt, setExcerpt] = useState();

  useEffect(() => {
    setExcerpt(responsiveStringLength(550, 1366, body, 80, 150, 300));

    const handleResize = window.addEventListener("resize", () => {
      setExcerpt(responsiveStringLength(550, 1366, body, 80, 150, 300));
    });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Link to={`/articles/${slug}`} className="featured">
      <BackgroundImage
        tag="div"
        className="content"
        fluid={image}
        backgroundColor={`#fbb040`}
      >
        <div className="label">ΠΡΟΣΦΑΤΟ</div>
        <div className="details">
          <p className="date">{date}</p>
          <h3 className="title">{title}</h3>
          <p className="excerpt">{excerpt}</p>
          <div className="read-more">+ Διαβάστε περισσότερα</div>
        </div>
      </BackgroundImage>
    </Link>
  );
};

export default Featured;
