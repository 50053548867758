import React from "react";

const RadioSpot = () => {
  return (
    <section className="radio-spot">
      <h2>Ραδιοφωνικό σποτ</h2>
      <hr />

      <iframe
        width="100%"
        height="150"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/870547354&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=true&visual=true"
      ></iframe>
      <div
        style={{
          fontSize: "10px",
          color: "#cccccc",
          lineBreak: "anywhere",
          wordBreak: "normal",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          fontFamily:
            "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
          fontWeight: 100,
        }}
      >
        <a
          href="https://soundcloud.com/user-686372955"
          title="Cyprus Atherosclerosis Society"
          target="_blank"
          style={{
            color: "#cccccc",
            textDecoration: "none",
          }}
        >
          Cyprus Atherosclerosis Society
        </a>{" "}
        ·{" "}
        <a
          href="https://soundcloud.com/user-686372955/cas-radiospot"
          title="CAS Radiospot"
          target="_blank"
          style={{
            color: "#cccccc",
            textDecoration: "none",
          }}
        >
          CAS Radiospot
        </a>
      </div>
    </section>
  );
};

export default RadioSpot;
