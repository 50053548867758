import React from "react";
import { useLatestNews } from "../../../hooks/useLatestNews";
import { FaLongArrowAltRight } from "react-icons/fa";

import Featured from "./featuredArticle";
import Other from "./otherArticles";
import { Link } from "gatsby";

const News = () => {
  const news = useLatestNews();

  const featured = news[0];
  const otherNews = news.slice(1, 5).map(article => {
    return <Other article={article} key={article.id} />;
  });

  return (
    <section className="news">
      <h2>Τελευταία Νέα</h2>
      <hr />
      <div className="wrapper">
        <Featured latestArticle={featured} />
        {otherNews}
        <Link to="/news-and-events" className="transparent-button news-cta">
          Περισσότερα <FaLongArrowAltRight />
        </Link>
      </div>
    </section>
  );
};

export default News;
