import { useStaticQuery, graphql } from "gatsby";

export const useCarouselImages = () => {
  const query = useStaticQuery(
    graphql`
      query {
        carouselImages: allContentfulHomepageCarousel(
          limit: 10
          sort: { fields: createdAt, order: ASC }
        ) {
          nodes {
            title
            id
            externalLink
            image {
              fluid(maxWidth: 1920, quality: 88) {
                src
              }
            }
          }
        }
        defaultImage: file(relativePath: { eq: "defaultBanner.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, jpegQuality: 90) {
              src
            }
          }
          name
        }
      }
    `
  );

  return query;
};
