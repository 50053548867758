import React from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import { useCarouselImages } from "../../hooks/useCarouselImages";

const Carousel = () => {
  const images = useCarouselImages();
  const carouselImages = images.carouselImages.nodes;
  const defaultImage = images.defaultImage;

  const params = {
    loop: true,
    speed: 1000,
    autoplay: {
      disableOnInteraction: false,
      delay: 5000,
    },
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
  };

  let carousel;

  if (
    carouselImages !== null &&
    carouselImages !== undefined &&
    carouselImages.length !== 0
  ) {
    carousel = carouselImages.map(node => {
      // Check if there is an external link attached
      if (node.externalLink) {
        return (
          <img
            src={node.image.fluid.src}
            alt={node.title}
            key={node.id}
            onClick={() => window.open(node.externalLink, "_blank")}
            style={{ cursor: "pointer" }}
          />
        );
      } else {
        return (
          <img src={node.image.fluid.src} alt={node.title} key={node.id} />
        );
      }
    });
  } else {
    // Load default image
    carousel = (
      <img
        src={defaultImage.childImageSharp.fluid.src}
        alt={defaultImage.name}
      />
    );
  }

  return (
    <div className="carousel">
      <Swiper {...params}>{carousel}</Swiper>
    </div>
  );
};

export default Carousel;
