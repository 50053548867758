import React from "react";
import { Link } from "gatsby";
import { IoMdArrowForward } from "react-icons/io";

const EventCard = ({ event }) => {
  let dateArr = event.eventDate.split(" ", 3);
  let day = dateArr[0];
  let month = dateArr[1];
  let year = dateArr[2];

  return (
    <Link to={`/articles/${event.slug}`} className="event-card">
      <div className="content">
        <div className="date">
          <span className="day">{day}</span>
          <span className="month">{month}</span>
          <span className="year">{year}</span>
        </div>
        <h4>{event.eventTitle}</h4>
        <IoMdArrowForward />
      </div>
      <hr />
    </Link>
  );
};

export default EventCard;
