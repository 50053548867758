import React from "react";
import { graphql } from "gatsby";

import "swiper/css/swiper.css";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Carousel from "../components/Homepage/carousel";
import PresidentGreetings from "../components/Homepage/presidentGreetings";
import RadioSpot from "../components/Homepage/RadioSpot";
import Sidebar from "../components/Homepage/Sidebar";
import News from "../components/Homepage/News";
import CallToAction from "../components/application-cta";

const Home = ({ data }) => {
  return (
    <Layout pageTitle="homepage">
      <SEO pageTitle="Αρχική" />
      <Carousel />
      <div className="page-wrapper">
        <div className="page-content">
          <PresidentGreetings />
          <News />
          <RadioSpot />
        </div>
        <Sidebar
          upcomingEvents={data.upcomingEvents.nodes}
          pastEvents={data.pastEvents.nodes}
        />
      </div>
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  query Events($dateNow: Float!) {
    upcomingEvents: allContentfulEvents(
      sort: { fields: eventDateTimestamp, order: DESC }
      limit: 3
      filter: { eventDateTimestamp: { gte: $dateNow } }
    ) {
      nodes {
        eventTitle
        eventDate(formatString: "DD MMM YYYY HH:mm")
        slug
        id
      }
    }
    pastEvents: allContentfulEvents(
      sort: { fields: eventDateTimestamp, order: DESC }
      limit: 2
      filter: { eventDateTimestamp: { lte: $dateNow } }
    ) {
      nodes {
        eventTitle
        eventDate(formatString: "DD MMM YYYY HH:mm")
        slug
        id
      }
    }
  }
`;

export default Home;
