import { useStaticQuery, graphql } from "gatsby";

export const useLatestNews = () => {
  const { news } = useStaticQuery(
    graphql`
      query {
        news: allContentfulNews(
          sort: { fields: createdAt, order: DESC }
          limit: 6
        ) {
          nodes {
            createdAt(formatString: "DD MMMM YYYY HH:mm")
            title
            slug
            body {
              json
            }
            featuredImage {
              fluid(quality: 100, maxHeight: 550) {
                src
              }
            }
            id
          }
        }
      }
    `
  );

  return news.nodes;
};
