import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const PresidentGreetings = () => {
  const data = useStaticQuery(
    graphql`
      query {
        greetings: contentfulPresidentsGreetings {
          title
          presidentImage {
            fluid(quality: 100, maxHeight: 600) {
              src
            }
          }
          message {
            json
          }
          signature {
            signature
          }
        }
      }
    `
  );

  let { greetings } = data;

  let signature = "";

  if (
    greetings.signature &&
    greetings.signature !== null &&
    greetings.signature !== undefined
  ) {
    signature = greetings.signature.signature.replace(/\n/g, "<br />");
  }

  return (
    <section className="greetings">
      <h2>{greetings.title}</h2>
      <hr />
      {documentToReactComponents(greetings.message.json)}
      {greetings.presidentImage && (
        <img src={greetings.presidentImage.fluid.src} />
      )}
      <p dangerouslySetInnerHTML={{ __html: signature }}></p>
    </section>
  );
};

export default PresidentGreetings;
