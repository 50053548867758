export default (string, charactersLength) => {
  let trimmed = string.substr(0, charactersLength)

  trimmed = trimmed.substr(
    0,
    Math.min(trimmed.length, trimmed.lastIndexOf(" "))
  )

  return trimmed
}
