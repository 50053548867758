import React from "react";
import { Link } from "gatsby";

import EventCard from "./EventCard";
import { FaLongArrowAltRight } from "react-icons/fa";

const Sidebar = ({ upcomingEvents, pastEvents }) => {
  const upcomingEventsCards = upcomingEvents.map(event => (
    <EventCard key={event.id} event={event} />
  ));

  const pastEventsCards = pastEvents.map(event => (
    <EventCard key={event.id} event={event} />
  ));

  return (
    <div className="page-sidebar">
      <div className="events-calendar">
        <h2>Ημερολόγιο Εκδηλώσεων</h2>
        <h3>Ανερχόμενες</h3>
        {upcomingEventsCards.length > 0 ? (
          upcomingEventsCards
        ) : (
          <p className="no-events-msg">Δεν υπάρχουν ανερχόμενες εκδηλώσεις.</p>
        )}
        <h3>Προηγούμενες</h3>
        {pastEventsCards.length > 0 ? (
          pastEventsCards
        ) : (
          <p className="no-events-msg">Δεν υπάρχουν προηγούμενες εκδηλώσεις.</p>
        )}
      </div>
      <div className="cta">
        <Link to="/events" className="transparent-button">
          Περισσότερα <FaLongArrowAltRight />
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
