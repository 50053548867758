import shortenStrings from "./shortenStrings"

export default (width_1, width_2, body, length_1, length_2, length_3) => {
  const innerWidth = window.innerWidth

  if (innerWidth <= width_1) {
    return `${shortenStrings(body, length_1)}...`
  } else if (innerWidth <= width_2) {
    return `${shortenStrings(body, length_2)}...`
  } else {
    return `${shortenStrings(body, length_3)}...`
  }
}
