import React from "react"
import { Link } from "gatsby"

import Pulse from "../images/pulse.svg"

const CallToAction = () => {
  return (
    <section className="application-cta">
      <div className="wrapper">
        <h2>
          Γίνε τώρα μέλος της <span>Κυπριακής Εταιρίας Αθηροσκλήρωσης</span>
        </h2>
        <Link to="/application-form" className="red-button">
          Αίτηση Εγγραφής
        </Link>
      </div>
      <Pulse alt="Pulse graphic" />
    </section>
  )
}

export default CallToAction
